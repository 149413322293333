import { Fragment } from 'react';

const BannerVideo = ({ video_fallback_img, video_webm, video_mp4 }) => {

    return (
        <Fragment>
            <video className="banner--bg" autoPlay playsInline loop muted poster={video_fallback_img}>
                <source src={video_webm} type="video/webm" />
                <source src={video_mp4} type="video/mp4" />
                <img src={video_fallback_img} alt="Browser does not support video tag" />
            </video>
        </Fragment>
    );
};

export default BannerVideo;
