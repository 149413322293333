import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import dataLayerPush from '@tlf-e/brand-utils/dist/helpers/dataLayerPush';
import setAffiliateData from '@tlf-e/brand-utils/dist/helpers/affilatedDataConfig/setAffiliateData';
import getCookie from '@tlf-e/brand-utils/dist/helpers/cookieConfig/getCookie';
import handleValidJson from '@tlf-e/brand-utils/dist/helpers/handleValidJson';
import setPromoStatus from '@tlf-e/brand-utils/dist/promotions/setPromoStatus';
import isLocalStorageAvailable from '@tlf-e/brand-utils/dist/helpers/isLocalStorageAvailable';
import {
    getGlobalInformation,
    setCookieAccepted,
    setLocalizedPaths,
    setModalStopSessionOpen,
    setOpenRealityCheckModal,
    setVideoAutoplayEnabled
} from '../store/reducers/global';
import { getPushNotifications, logoutUser, setSocketDisconnect } from '../store/reducers/user';
import { getSocketBalance, setFirstDepositLoadingTime } from '../store/reducers/bank';
import { userWorkers } from '../services/http';
import io from 'socket.io-client';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { withRedux } from './hoc/withRedux';
import { socketUrl } from '../services/http';
import { localizePath } from '../utils/locationConfig';
import {
    COOKIE_NAME_PRIVACY_POLICY,
    GLOBAL_ERROR_MESSAGE_KEY,
    TRANSACTION_STATUS_SUCCESS,
    WS_IS_LOADING
} from '../variables';
import { withWheelWidget } from './hoc/withWheelWidget';
import { withRealityCheck } from './hoc/withRealityCheck';
import { withSideMenu } from './hoc/withSideMenu';
import { withNotifications } from './hoc/withNotifications';
import { getBonusData } from '../store/reducers/bonus';
import { updatePromotionsList } from '../store/reducers/promotions';
import useSetupSeon from '@tlf-e/brand-utils/dist/hooks/useSetupSeon';
import { withMysteryChestsSetup } from './hoc/withMysteryChestsSetup';
import { setNotificationUpdateStatus } from '../store/reducers/kyc';
import { kycNotifications } from '../data/kycData';

const Root = ({ children, isError }) => {
    const { isAuth, disconnectSocket } = useSelector((state) => state.user);
    const { firstDepositRemainingTime } = useSelector((state) => state.bank);
    const { tokenTime } = useSelector((state) => state.global);
    const userInformation = useSelector((state) => state.user.information);
    const { allPromotions, promotionToBeUpdated } = useSelector((state) => state.promotions);
    const dispatch = useDispatch();
    const router = useRouter();
    const { aid, at, referralToken } = router.query;
    const balanceUpdatedAt = useSelector((state) => state.user.balanceUpdated);
    const transactionStatus = useSelector((state) => state.bank.transactionStatus.status);
    const { loadSeonError, configureSeonError } = useSetupSeon(uuidv4);

    if (loadSeonError || configureSeonError) dataLayerPush({ event: 'getSeonBase64Session_error', error: 'load' });

    //using one time across the application
    useEffect(() => {
        dayjs.locale(router.locale);
        dispatch(setLocalizedPaths(router.locale));
        //import module only on client side
        import('can-autoplay')
            .then((module) => {
                module.default
                    .video({ timeout: 100, muted: true })
                    .then(({ result }) => dispatch(setVideoAutoplayEnabled(result)))
                    .catch(() => dispatch(setVideoAutoplayEnabled(false)));
            })
            .catch(() => dispatch(setVideoAutoplayEnabled(false)));
        if (!isError) {
            dispatch(getGlobalInformation(router.locale));
            dispatch(setCookieAccepted(getCookie(COOKIE_NAME_PRIVACY_POLICY)));
            if (isLocalStorageAvailable()) {
                localStorage.removeItem(GLOBAL_ERROR_MESSAGE_KEY);
            }
        }
        if (parseInt(aid) > 0 && at) {
            setAffiliateData('aid', aid);
            setAffiliateData('at', at);
        }
        if (referralToken) {
            setAffiliateData('referralToken', referralToken);
        }
        localizePath(router);
        /* Post a message to catch it under Game.js
         * To handle "go to lobby" in-iframe action
         * when game reload app inside this iframe
         */
        window.top.postMessage && window.top.postMessage(WS_IS_LOADING, '*');
    }, []);

    useEffect(() => {
        //handle user inactivity
        const interval = setInterval(() => {
            if (isAuth) {
                userWorkers.ping().catch((err) => {
                    if ([401, 403].includes(err?.status)) {
                        err.status === 403 && dispatch(logoutUser());
                        dispatch(setModalStopSessionOpen(true));
                        dispatch(setOpenRealityCheckModal(false));
                    }
                });
            }
        }, 60000);
        return () => clearInterval(interval);
    }, [isAuth, balanceUpdatedAt, tokenTime]);

    useEffect(() => {
        //handle sockets
        if (isAuth && userInformation.id) {
            const socket = io(socketUrl, {
                'sync disconnect on unload': true,
                transports: ['websocket'],
                upgrade: false,
                reconnection: true,
                reconnectionDelay: 5000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 1500
            });
            socket.on('connect', function () {
                dispatch(setSocketDisconnect(() => socket.disconnect()));
                socket.emit('client', userInformation.id);
                socket.on('balance', function (data) {
                    const balance = JSON.parse(data);
                    dispatch(getSocketBalance(balance));
                });
                socket.on('sendnotification', function () {
                    dispatch(getPushNotifications(router.locale));
                });
                socket.on('document', function (data) {
                    const document = handleValidJson(data) && JSON.parse(data);
                    if (document && Object.keys(kycNotifications).includes(document.toStatus)) {
                        dispatch(setNotificationUpdateStatus(document));
                    }
                });
            });
            window.onbeforeunload = () => {
                socket.disconnect();
            };
        } else {
            disconnectSocket();
            dispatch(setSocketDisconnect(() => null));
        }
    }, [userInformation]);

    useEffect(() => {
        if (isAuth && userInformation.id) {
            const { firstName, lastName, email, countryPhoneCodePrefix, phoneNumber, vipLevel } = userInformation;
            const info = {
                name: `${firstName} ${lastName}`,
                email: email,
                brand: 'Wild Sultan',
                phone: `${countryPhoneCodePrefix}${phoneNumber}`
            };

            const isValidZopimEnvironment = () =>
                typeof window.zE === 'function' && typeof window.zE.identify === 'function';

            const addVIPTag = () => {
                if (vipLevel?.rank) {
                    window.zE('webWidget', 'chat:addTags', [`WS_VIP_${vipLevel.rank}`]);
                }
            };

            const sendUserDataToZe = () => {
                window.zE.identify(info);
                addVIPTag();
            };

            if (isValidZopimEnvironment()) {
                sendUserDataToZe();
            } else {
                const listener = (e) => {
                    if (
                        e.key === '__zlcstore' &&
                        typeof window.$zopim === 'function' &&
                        isValidZopimEnvironment()
                    ) {
                        sendUserDataToZe();
                    }
                };

                window.addEventListener('storage', listener);

                return () => window.removeEventListener('storage', listener);
            }
        }
    }, [userInformation.id]);

    // Getting bonus data after success transaction.
    // API has some delay, so we need to wait for it to get actual status of bonus
    useEffect(() => {
        if (transactionStatus === TRANSACTION_STATUS_SUCCESS) {
            const timer = setTimeout(() => dispatch(getBonusData()), 1000);
            return () => clearTimeout(timer);
        }
    }, [transactionStatus]);

    //first deposit bonus timer
    useEffect(() => {
        if (isAuth) {
            const intervalId = setInterval(() => {
                dispatch(setFirstDepositLoadingTime(firstDepositRemainingTime - 1));
            }, 1000);

            if (firstDepositRemainingTime === 0) {
                clearInterval(intervalId);
            }

            return () => clearInterval(intervalId);
        }
    }, [isAuth, firstDepositRemainingTime]);

    // Update promotions list if status of promo changed
    useEffect(() => {
        if (promotionToBeUpdated) {
            const timer = setTimeout(() => {
                const updatedPromos = allPromotions.map((promo) => ({
                    ...promo,
                    ...setPromoStatus(promo.dateTimeStart, promo.dateTimeEnd)
                }));
                dispatch(updatePromotionsList(updatedPromos));
            }, promotionToBeUpdated.timerTimestamp);
            return () => clearTimeout(timer);
        }
    }, [promotionToBeUpdated?.timerTimestamp, promotionToBeUpdated?.promoId]);

    return children;
};

export default withRedux(withWheelWidget(withMysteryChestsSetup(withRealityCheck(withSideMenu(withNotifications(Root))))));
