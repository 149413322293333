import router from 'next/router';
import dayjs from 'dayjs';
import isPlainObject from 'lodash/isPlainObject';
import minBy from 'lodash/minBy';
import getOrderedPaymentMethods from '@tlf-e/brand-utils/dist/helpers/bankUtils/getOrderedPaymentMethods';
import formatCardSuffix from '@tlf-e/brand-utils/dist/helpers/bankUtils/formatCardSuffix';
import handlePendingWithdrawals from '@tlf-e/brand-utils/dist/helpers/commonConfig/handlePendingWithdrawals';
import getSeonBase64Session from '@tlf-e/brand-utils/dist/helpers/seonUtils';
import dataLayerPush from '@tlf-e/brand-utils/dist/helpers/dataLayerPush';
import handleDepositError from '@tlf-e/brand-utils/dist/error-handling/handleDepositError';
import getLoadingPercent from '@tlf-e/brand-utils/dist/helpers/getLoadingPercent';
import scrollElementToTop from '@tlf-e/brand-utils/dist/helpers/scrollConfig/scrollElementToTop';
import { bankWorkers, bonusWorkers, userWorkers } from '../../services/http';
import { finishSession } from './user';
import { setLoadingPercent } from './global';
import {
    PAYMENT_METHOD_VISA,
    PAYMENT_METHOD_MASTERCARD,
    PAYMENT_METHOD_MAESTRO,
    PAYMENT_METHOD_TYPE_REUSED_CARD,
    TRANSACTION_STATUS_NOT_FOUND,
    TRANSACTION_TYPE_WITHDRAW,
    TRANSACTION_STATUS_FAILURE,
    TRANSACTION_STATUS_SUCCESS,
    TRANSACTION_STATUS_CANCELED,
    TRANSACTION_STATUS_ERROR,
    TRANSACTION_STATUS_FRAUD,
    TRANSACTION_STATUS_REFUSED,
    TRANSACTION_STATUS_TIMEOUT,
    PAYOUT_STATUS_PENDING,
    PAYOUT_STATUS_VALIDATED,
    PAYOUT_STATUS_PROCESSING,
    PAYOUT_STATUS_REQUESTED,
    PAYOUT_STATUS_ERROR,
    DEPOSIT_SUBMISSION,
    MODAL_STATUS_SUCCESS,
    TRANSACTION_STATUS_PENDING,
    NOTIFICATIONS_STATUS_SUCCESS,
    NOTIFICATIONS_STATUS_ERROR,
    PENDING_TIME_INTERVAL_0,
    PENDING_TIME_INTERVAL_1,
    PENDING_TIME_INTERVAL_2,
    PENDING_TIME_INTERVAL_3,
    PENDING_TIME_INTERVAL_4,
    TRANSACTIONS_LOADING_TYPE_GLOBAL,
    TRANSACTIONS_LOADING_TYPE_BUTTON,
    TRANSACTION_ID,
    LOCAL_STORAGE_IS_DEP_SUCCESS,
    DEPOSIT_START,
    MODAL_DEPOSIT,
    MODAL_WITHDRAW,
    PAYMENT_METHOD_SYNTHETIC_NEW_BANK_CARD,
    DESC,
    PAYMENT_METHOD_CASHLIB_V2,
    PAYMENT_METHOD_ONLINEBANKING,
    COUNTRY_CODE_CA,
    PAYOUT_METHOD_INTERAC,
    PAYMENT_METHOD_APPLE_PAY,
    DEPOSIT_RESPONSE_TYPE_REDIRECT
} from '../../variables';
import routes from '../../data/routes.json';
import betRaceMilestonesData from '../../data/BetRaceMilestones.json';
import { getBonusData } from './bonus';
import { handleCloseDepositModal, handleCloseModal } from '../../utils/locationConfig';
import { getContentForSlider } from './slider';

const {
    walletInformationRequest,
    depositRequest,
    withdrawalRequest,
    transactionStatusRequest,
    transactionsRequest,
    userCardsRequest,
    cancelWithdrawalRequest,
    getPaymentConfig,
    firstDepositRemainingTimeRequest,
    getCashlibDirectBarcodeRequest
} = bankWorkers;
const { bonusCancelRequest } = bonusWorkers;
const { getBetRaceRequest } = userWorkers;

const GET_WALLET_INFORMATION = 'GET_WALLET_INFORMATION';
const GET_WALLET_INFORMATION_SUCCESS = 'GET_WALLET_INFORMATION_SUCCESS';
const GET_WALLET_INFORMATION_FAILED = 'GET_WALLET_INFORMATION_FAILED';
const SUBMIT_DEPOSIT = 'SUBMIT_DEPOSIT';
const SUBMIT_DEPOSIT_SUCCESS = 'SUBMIT_DEPOSIT_SUCCESS';
const SUBMIT_DEPOSIT_FAIL = 'SUBMIT_DEPOSIT_FAIL';
const SUBMIT_DEPOSIT_INIT = 'SUBMIT_DEPOSIT_INIT';
const SUBMIT_DEPOSIT_CASHLIB_DIRECT = 'SUBMIT_DEPOSIT_CASHLIB_DIRECT';
const SUBMIT_DEPOSIT_CASHLIB_DIRECT_SUCCESS = 'SUBMIT_DEPOSIT_CASHLIB_DIRECT_SUCCESS';
const SUBMIT_DEPOSIT_CASHLIB_DIRECT_FAIL = 'SUBMIT_DEPOSIT_CASHLIB_DIRECT_FAIL';
const SUBMIT_WITHDRAW = 'SUBMIT_WITHDRAW';
const SUBMIT_WITHDRAW_SUCCESS = 'SUBMIT_WITHDRAW_SUCCESS';
const SUBMIT_WITHDRAW_FAIL = 'SUBMIT_WITHDRAW_FAIL';
const SUBMIT_WITHDRAW_INIT = 'SUBMIT_WITHDRAW_INIT';
const GET_TRANSACTION_STATUS = 'GET_TRANSACTION_STATUS';
const GET_TRANSACTION_STATUS_SUCCESS = 'GET_TRANSACTION_STATUS_SUCCESS';
const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
const GET_TRANSACTIONS_FAILED = 'GET_TRANSACTIONS_FAILED';
const GET_WITHDRAWALS_WALLET = 'GET_WITHDRAWALS_WALLET';
const GET_WITHDRAWALS_WALLET_SUCCESS = 'GET_WITHDRAWALS_WALLET_SUCCESS';
const GET_WITHDRAWALS_WALLET_FAILED = 'GET_WITHDRAWALS_WALLET_FAILED';
const SELECT_BONUS = 'SELECT_BONUS';
const TRANSACTION_STATUS_INIT = 'TRANSACTION_STATUS_INIT';
const SET_DEPOSIT_ACTIVE_STEP = 'SET_DEPOSIT_ACTIVE_STEP';
const GET_SOCKET_BALANCE = 'GET_SOCKET_BALANCE';
const GET_BET_RACE = 'GET_BET_RACE';
const GET_PAYOUT_METHODS = 'GET_PAYOUT_METHODS';
const GET_PAYOUT_METHODS_SUCCESS = 'GET_PAYOUT_METHODS_SUCCESS';
const GET_PAYOUT_METHODS_FAILED = 'GET_PAYOUT_METHODS_FAILED';
const INIT_PAYOUT_METHODS = 'INIT_PAYOUT_METHODS';
const CANCEL_WITHDRAWAL = 'CANCEL_WITHDRAWAL';
const CANCEL_WITHDRAWAL_SUCCESS = 'CANCEL_WITHDRAWAL_SUCCESS';
const CANCEL_WITHDRAWAL_FAIL = 'CANCEL_WITHDRAWAL_FAIL';
const CLEANUP_CANCEL_WITHDRAWAL = 'CLEANUP_CANCEL_WITHDRAWAL';
const SET_DEPOSIT_CLOSE_HANDLER = 'SET_DEPOSIT_CLOSE_HANDLER';
const SET_CONVERT_CRYPTO_MODAL_OPEN = 'SET_CONVERT_CRYPTO_MODAL_OPEN';
const SET_SUCCESSFULL_DEPOSIT_COUNT = 'SET_SUCCESSFULL_DEPOSIT_COUNT';
const SET_WITHDRAWAL_RESULT_MODAL = 'SET_WITHDRAWAL_RESULT_MODAL';
const SELECT_WITHDRAWAL_METHOD = 'SELECT_WITHDRAWAL_METHOD';
const SET_PENDING_STATUS_INTERVAL_TIME = 'SET_PENDING_STATUS_INTERVAL_TIME';
const SET_PENDING_STATUS_NOTIFICATION = 'SET_PENDING_STATUS_NOTIFICATION';
const SET_PENDING_STATUS_ID = 'SET_PENDING_STATUS_ID';
const GET_FIRST_DEPOSIT_REMAINING_TIME = 'GET_FIRST_DEPOSIT_REMAINING_TIME';
const GET_FIRST_DEPOSIT_REMAINING_TIME_SUCCESS = 'GET_FIRST_DEPOSIT_REMAINING_TIME_SUCCESS';
const GET_FIRST_DEPOSIT_REMAINING_TIME_FAILED = 'GET_FIRST_DEPOSIT_REMAINING_TIME_FAILED';
const SET_FIRST_DEPOSIT_OFFER_MODAL_OPENED = 'SET_FIRST_DEPOSIT_OFFER_MODAL_OPENED';
const SET_FIRST_DEPOSIT_OFFER_BUBBLE_VISIBLE = 'SET_FIRST_DEPOSIT_OFFER_BUBBLE_VISIBLE';
const INIT_BANK_INFORMATION = 'INIT_BANK_INFORMATION';
const GET_PAYMENT_METHODS_NEW = 'GET_PAYMENT_METHODS_NEW';
const GET_PAYMENT_METHODS_NEW_SUCCESS = 'GET_PAYMENT_METHODS_NEW_SUCCESS';
const GET_PAYMENT_METHODS_NEW_FAILED = 'GET_PAYMENT_METHODS_NEW_FAILED';
const GET_PENDING_WITHDRAWALS_DEPOSIT_NEW = 'GET_PENDING_WITHDRAWALS_DEPOSIT_NEW';
const GET_PENDING_WITHDRAWALS_DEPOSIT_NEW_SUCCESS = 'GET_PENDING_WITHDRAWALS_DEPOSIT_NEW_SUCCESS';
const GET_PENDING_WITHDRAWALS_DEPOSIT_NEW_FAILED = 'GET_PENDING_WITHDRAWALS_DEPOSIT_NEW_FAILED';
const SET_DEPOSIT_CURRENT_VIEW = 'SET_DEPOSIT_CURRENT_VIEW';
const SET_ACTIVE_CARD_METHODS = 'SET_ACTIVE_CARD_METHODS';
const SET_DEPOSIT_AMOUNT = 'SET_DEPOSIT_AMOUNT';
const SELECT_METHOD_NEW = 'SELECT_METHOD_NEW';

//ABORT CONTROLLERS
export let depositRequestAbortController = null;
export let withdrawalRequestAbortController = null;

export const transactionsPerPage = {
    desktop: 15,
    mobile: 10
};
export const vouchersMaxQuantity = 4;

export const bankCardMethods = [
    PAYMENT_METHOD_VISA,
    PAYMENT_METHOD_MASTERCARD,
    PAYMENT_METHOD_MAESTRO,
    PAYMENT_METHOD_SYNTHETIC_NEW_BANK_CARD
];

export const cardMethods = [
    PAYMENT_METHOD_VISA,
    PAYMENT_METHOD_MASTERCARD,
    PAYMENT_METHOD_MAESTRO,
    PAYMENT_METHOD_TYPE_REUSED_CARD
];

//Default ordering of methods to prevent extra request to API
const paymentMethodsOrdering = [
    PAYMENT_METHOD_MASTERCARD,
    PAYMENT_METHOD_VISA,
    PAYMENT_METHOD_CASHLIB_V2,
    PAYMENT_METHOD_ONLINEBANKING
];

const transactionStatuses = [
    TRANSACTION_STATUS_FAILURE,
    TRANSACTION_STATUS_SUCCESS,
    TRANSACTION_STATUS_CANCELED,
    TRANSACTION_STATUS_ERROR,
    TRANSACTION_STATUS_FRAUD,
    TRANSACTION_STATUS_REFUSED,
    TRANSACTION_STATUS_TIMEOUT
];

export const amountRange = ['50', '100', '500'];

export const initialState = {
    information: {
        balance: {},
        depositLimit: {},
        depositLimitRemaining: {},
        successfulDepositCount: 0
    },
    betRace: {
        currentWager: 0,
        previousWagerReached: 0,
        activeMilestone: 1,
        betRaceProgress: 0
    },
    betRaceLoaded: false,
    isWalletInformationLoading: false,
    payoutMethods: [],
    payoutMethodsLoading: false,
    payoutMethodsError: false,
    enabledPayoutMethods: [],
    selectedWithdrawalMethod: {},
    submitDeposit: {
        amount: null,
        method: null,
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: ''
    },
    submitWithdraw: {
        amount: null,
        method: null,
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: ''
    },
    cancelWithdrawalLoadingId: null,
    cancelWithdrawalSuccess: false,
    cancelWithdrawalError: false,
    cancelWithdrawalData: null,
    cancelWithdrawalErrorMessage: '',
    depositResponse: {
        url: '',
        type: ''
    },
    transactionStatusLoading: false,
    transactionStatus: {
        status: '',
        error: null
    },
    transactionData: {
        amount: '',
        method: ''
    },
    transactions: {
        loadingType: '',
        list: [],
        offset: 0,
        totalItems: 0
    },
    withdrawalsWallet: {
        loadingType: '',
        list: [],
        offset: 0,
        totalItems: 0
    },
    selectedBonus: '',
    depositActiveStep: 1,
    depositCloseHandler: handleCloseDepositModal,
    isConvertCryptoModalOpen: false,
    withdrawalResultModal: {
        status: '',
        data: {}
    },
    pendingStatusIntervalTime: 0,
    pendingStatusNotification: '',
    pendingStatusId: null,
    firstDepositRemainingTime: 0,
    firstDepositRemainingTimeLoading: false,
    firstDepositOfferModalOpened: false,
    firstDepositOfferBubbleVisible: false,
    paymentMethodsNew: {
        bankCards: [],
        other: []
    },
    paymentMethodsNewLoading: false,
    paymentMethodsNewLoaded: false,
    activeCardMethods: [],
    depositAmount: '',
    selectedMethodNew: {},
    depositCurrentView: '',
    withdrawalsDeposit: {
        loadingType: '',
        list: [],
        offset: 0,
        totalItems: 0
    }
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case GET_WALLET_INFORMATION:
        return {
            ...state,
            isWalletInformationLoading: true
        };
    case GET_WALLET_INFORMATION_SUCCESS:
        return {
            ...state,
            information: {
                ...action.payload.data,
                balance: {
                    ...action.payload.data.balance,
                    timestamp: dayjs().unix()
                }
            },
            isWalletInformationLoading: false
        };
    case GET_WALLET_INFORMATION_FAILED:
        return {
            ...state,
            isWalletInformationLoading: false
        };
    case SUBMIT_DEPOSIT:
    case SUBMIT_DEPOSIT_CASHLIB_DIRECT:
        return {
            ...state,
            submitDeposit: {
                amount: null,
                method: null,
                isLoading: true,
                isSuccess: false,
                isError: false,
                error: ''
            }
        };
    case SUBMIT_DEPOSIT_SUCCESS:
        return {
            ...state,
            submitDeposit: {
                amount: action.amount,
                method: action.method,
                isLoading: false,
                isSuccess: true,
                isError: false,
                error: ''
            },
            ...handlePaymentMethod(action.payload, action.method, action.amount)
        };
    case SUBMIT_DEPOSIT_FAIL:
    case SUBMIT_DEPOSIT_CASHLIB_DIRECT_FAIL:
        return {
            ...state,
            submitDeposit: {
                amount: null,
                method: null,
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        };
    case SUBMIT_DEPOSIT_INIT:
        return {
            ...state,
            submitDeposit: {
                amount: null,
                method: null,
                isLoading: false,
                isSuccess: false,
                isError: false,
                error: ''
            }
        };
    case SUBMIT_DEPOSIT_CASHLIB_DIRECT_SUCCESS:
        return {
            ...state,
            depositResponse: {
                url: action.payload,
                type: DEPOSIT_RESPONSE_TYPE_REDIRECT
            },
            submitDeposit: {
                amount: null,
                method: null,
                isLoading: false,
                isSuccess: true,
                isError: false,
                error: ''
            }
        };
    case SUBMIT_WITHDRAW:
        return {
            ...state,
            submitWithdraw: {
                amount: null,
                method: null,
                isLoading: true,
                isSuccess: false,
                isError: false,
                error: ''
            }
        };
    case SUBMIT_WITHDRAW_SUCCESS:
        return {
            ...state,
            submitWithdraw: {
                amount: action.amount,
                method: action.method,
                isLoading: false,
                isSuccess: true,
                isError: false,
                error: ''
            }
        };
    case SUBMIT_WITHDRAW_FAIL:
        return {
            ...state,
            submitWithdraw: {
                amount: null,
                method: null,
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: JSON.parse(action.payload)
            }
        };
    case SUBMIT_WITHDRAW_INIT:
        return {
            ...state,
            submitWithdraw: {
                isLoading: false,
                isSuccess: false,
                isError: false,
                error: ''
            }
        };
    case GET_TRANSACTION_STATUS:
        return {
            ...state,
            transactionStatusLoading: true
        };
    case GET_TRANSACTION_STATUS_SUCCESS:
        return {
            ...state,
            transactionStatusLoading: false,
            transactionStatus: {
                status: action.payload.data.status,
                error: action.payload.data.errors
            },
            depositResponse: {
                url: '',
                type: ''
            }
        };
    case GET_TRANSACTIONS:
        return {
            ...state,
            transactions: {
                ...state.transactions,
                loadingType: action.payload
            }
        };
    case GET_TRANSACTIONS_SUCCESS:
        return {
            ...state,
            transactions: {
                ...action.payload,
                loadingType: ''
            }
        };
    case GET_TRANSACTIONS_FAILED:
        return {
            ...state,
            transactions: {
                ...state.transactions,
                loadingType: ''
            }
        };
    case GET_WITHDRAWALS_WALLET:
        return {
            ...state,
            withdrawalsWallet: {
                ...state.withdrawalsWallet,
                loadingType: action.payload
            }
        };
    case GET_WITHDRAWALS_WALLET_SUCCESS:
        return {
            ...state,
            withdrawalsWallet: {
                ...action.payload,
                loadingType: ''
            }
        };
    case GET_WITHDRAWALS_WALLET_FAILED:
        return {
            ...state,
            withdrawalsWallet: {
                ...state.withdrawalsWallet,
                loadingType: ''
            }
        };
    case SELECT_BONUS:
        return {
            ...state,
            selectedBonus: action.payload
        };
    case TRANSACTION_STATUS_INIT:
        return {
            ...state,
            transactionStatus: {
                status: '',
                error: null
            },
            transactionData: {
                amount: '',
                method: ''
            },
            depositResponse: {
                url: '',
                type: ''
            }
        };
    case SET_DEPOSIT_ACTIVE_STEP:
        return {
            ...state,
            depositActiveStep: action.payload
        };
    case GET_SOCKET_BALANCE:
        const payload = action.payload;
        const { balance } = state.information;
        const isLastMilestoneFinished = state.betRace.currentWager >= betRaceMilestonesData.slice(-1).wager;
        const shouldUpdate = !balance.timestamp || payload.timestamp > balance.timestamp;
        const relevantBalance = shouldUpdate ? payload : balance;
        const currentMilestone = payload.betRace?.currentWager
            ? betRaceMilestonesData.find((item) => payload.betRace.currentWager <= item.wager)
            : betRaceMilestonesData.find((item) => state.betRace.activeMilestone === item.id);
        const prevMilestone = currentMilestone?.id > 1 ? betRaceMilestonesData[currentMilestone?.id - 2] : null;
        return {
            ...state,
            information: {
                ...state.information,
                balance: relevantBalance
            },
            betRace: {
                ...state.betRace,
                currentWager: payload.betRace?.currentWager || state.betRace.currentWager,
                betRaceProgress: isLastMilestoneFinished
                    ? 100
                    : Math.floor(
                        currentMilestone?.id === 1
                            ? payload.betRace?.currentWager
                                ? (payload.betRace.currentWager * 100) / currentMilestone?.wager
                                : state.betRace.betRaceProgress
                            : payload.betRace?.currentWager
                                ? ((payload.betRace.currentWager - prevMilestone?.wager) * 100) /
                                      (currentMilestone?.wager - prevMilestone?.wager)
                                : state.betRace.betRaceProgress
                    ),
                activeMilestone: isLastMilestoneFinished ? 5 : currentMilestone?.id
            }
        };
    case GET_BET_RACE:
        const { currentWager, previousWagerReached } = action.payload.data;
        const lastOneMilestone = betRaceMilestonesData.slice(-1);
        const milestonesFinished = currentWager >= lastOneMilestone.wager;
        const headerMilestone = milestonesFinished
            ? null
            : betRaceMilestonesData.find((item) => currentWager <= item.wager);
        const previousMilestone = milestonesFinished
            ? null
            : headerMilestone.id > 1
                ? betRaceMilestonesData.find((item) => item.id === headerMilestone.id - 1)
                : null;

        return {
            ...state,
            betRace: {
                currentWager,
                previousWagerReached,
                activeMilestone: milestonesFinished ? 5 : headerMilestone.id,
                betRaceProgress: milestonesFinished
                    ? 100
                    : Math.floor(
                        headerMilestone.id === 1
                            ? (currentWager * 100) / headerMilestone.wager
                            : ((currentWager - previousMilestone.wager) * 100) /
                                        (headerMilestone.wager - previousMilestone.wager)
                    )
            },
            betRaceLoaded: true
        };
    case GET_PAYOUT_METHODS:
        return {
            ...state,
            payoutMethodsLoading: true
        };
    case GET_PAYOUT_METHODS_SUCCESS:
        const enabledPayoutMethods = [...action.payload.data].sort((a, b) => (a.method < b.method ? -1 : 1));
        return {
            ...state,
            payoutMethodsLoading: false,
            payoutMethodsError: false,
            payoutMethods: action.payload.data,
            enabledPayoutMethods,
            selectedWithdrawalMethod: enabledPayoutMethods.length > 0 ? enabledPayoutMethods[0] : {}
        };
    case GET_PAYOUT_METHODS_FAILED:
        return {
            ...state,
            payoutMethodsLoading: false,
            payoutMethodsError: true
        };
    case CANCEL_WITHDRAWAL:
        return {
            ...state,
            cancelWithdrawalLoadingId: action.payload
        };
    case CANCEL_WITHDRAWAL_SUCCESS:
        const { amount, currency } = action.payload;
        return {
            ...state,
            cancelWithdrawalSuccess: true,
            cancelWithdrawalError: false,
            cancelWithdrawalLoadingId: null,
            cancelWithdrawalData: {
                amount,
                currency
            }
        };
    case CANCEL_WITHDRAWAL_FAIL:
        return {
            ...state,
            cancelWithdrawalSuccess: false,
            cancelWithdrawalError: true,
            cancelWithdrawalData: null,
            cancelWithdrawalErrorMessage: action.payload.data,
            cancelWithdrawalLoadingId: null
        };
    case CLEANUP_CANCEL_WITHDRAWAL:
        return {
            ...state,
            cancelWithdrawalSuccess: false,
            cancelWithdrawalError: false,
            cancelWithdrawalLoadingId: null,
            cancelWithdrawalData: null,
            cancelWithdrawalErrorMessage: ''
        };
    case SET_DEPOSIT_CLOSE_HANDLER:
        return {
            ...state,
            depositCloseHandler: action.payload
        };
    case SET_CONVERT_CRYPTO_MODAL_OPEN:
        return {
            ...state,
            isConvertCryptoModalOpen: action.payload
        };
    case SET_SUCCESSFULL_DEPOSIT_COUNT:
        return {
            ...state,
            information: {
                ...state.information,
                successfulDepositCount: action.payload
            }
        };
    case SET_WITHDRAWAL_RESULT_MODAL:
        return {
            ...state,
            withdrawalResultModal: action.payload
        };
    case SELECT_WITHDRAWAL_METHOD:
        return {
            ...state,
            selectedWithdrawalMethod: state.enabledPayoutMethods.find((item) => item.method === action.payload) || {}
        };
    case SET_PENDING_STATUS_INTERVAL_TIME:
        return {
            ...state,
            pendingStatusIntervalTime: action.payload
        };
    case SET_PENDING_STATUS_NOTIFICATION:
        return {
            ...state,
            pendingStatusNotification: action.payload
        };
    case SET_PENDING_STATUS_ID:
        return {
            ...state,
            pendingStatusId: action.payload
        };
    case GET_FIRST_DEPOSIT_REMAINING_TIME:
        return {
            ...state,
            firstDepositRemainingTimeLoading: true
        };
    case GET_FIRST_DEPOSIT_REMAINING_TIME_SUCCESS:
        return {
            ...state,
            firstDepositRemainingTime: action.payload,
            firstDepositRemainingTimeLoading: false
        };
    case GET_FIRST_DEPOSIT_REMAINING_TIME_FAILED:
        return {
            ...state,
            firstDepositRemainingTimeLoaded: false
        };
    case SET_FIRST_DEPOSIT_OFFER_MODAL_OPENED:
        return {
            ...state,
            firstDepositOfferModalOpened: action.payload
        };
    case SET_FIRST_DEPOSIT_OFFER_BUBBLE_VISIBLE:
        return {
            ...state,
            firstDepositOfferBubbleVisible: action.payload
        };
    case SET_ACTIVE_CARD_METHODS:
        return {
            ...state,
            activeCardMethods: action.payload
        };
    case SET_DEPOSIT_AMOUNT:
        return {
            ...state,
            depositAmount: action.payload
        };
    case GET_PENDING_WITHDRAWALS_DEPOSIT_NEW:
        return {
            ...state,
            withdrawalsDeposit: {
                ...state.withdrawalsDeposit,
                loadingType: action.payload
            }
        };
    case GET_PENDING_WITHDRAWALS_DEPOSIT_NEW_SUCCESS:
        return {
            ...state,
            withdrawalsDeposit: {
                ...action.payload,
                loadingType: ''
            }
        };
    case GET_PENDING_WITHDRAWALS_DEPOSIT_NEW_FAILED:
        return {
            ...state,
            withdrawalsDeposit: {
                ...state.withdrawalsDeposit,
                loadingType: ''
            }
        };
    case GET_PAYMENT_METHODS_NEW:
        return {
            ...state,
            paymentMethodsNewLoading: true
        };
    case GET_PAYMENT_METHODS_NEW_SUCCESS:
        return {
            ...state,
            paymentMethodsNewLoading: false,
            paymentMethodsNewLoaded: true,
            paymentMethodsNew: action.payload
        };
    case GET_PAYMENT_METHODS_NEW_FAILED:
        return {
            ...state,
            paymentMethodsNewLoading: false,
            paymentMethodsNewLoaded: true
        };
    case SET_DEPOSIT_CURRENT_VIEW:
        return {
            ...state,
            depositCurrentView: action.payload
        };
    case SELECT_METHOD_NEW:
        return {
            ...state,
            selectedMethodNew: action.payload
        };
    case INIT_PAYOUT_METHODS:
        return {
            ...state,
            payoutMethodsError: false
        };
    case INIT_BANK_INFORMATION:
        return initialState;
    default:
        return state;
    }
};

export const getWalletInformation = ({ getFirstDepositRemainingTime, isTransactionSuccess = false }) => {
    const loading = () => ({
        type: GET_WALLET_INFORMATION
    });
    const success = (payload) => ({
        type: GET_WALLET_INFORMATION_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_WALLET_INFORMATION_FAILED
    });
    return (dispatch) => {
        dispatch(loading());
        walletInformationRequest()
            .then((res) => {
                const hasSavedSuccessfulDeposits = res.data.data.successfulDepositCount > 0;
                const cashedDepositSuccess = !!localStorage.getItem(LOCAL_STORAGE_IS_DEP_SUCCESS);
                const hasSuccessfulDeposit = hasSavedSuccessfulDeposits || isTransactionSuccess || cashedDepositSuccess;
                const onSuccess = () => {
                    dispatch(success(res.data));
                    dispatch(
                        setLoadingPercent(getLoadingPercent(!!res.config.headers['Authorization'], 'walletInformation'))
                    );
                };

                // Manage localStorage temp data in case of no-sync data of successfulDepositCount from response
                if (hasSavedSuccessfulDeposits && cashedDepositSuccess) {
                    localStorage.removeItem(LOCAL_STORAGE_IS_DEP_SUCCESS);
                } else if (!hasSavedSuccessfulDeposits && !cashedDepositSuccess && isTransactionSuccess) {
                    localStorage.setItem(LOCAL_STORAGE_IS_DEP_SUCCESS, 'true');
                }

                // The logic considers async wallet information data in case of successful deposits
                if (getFirstDepositRemainingTime && !hasSuccessfulDeposit) {
                    return getFirstDepositRemainingTime(onSuccess);
                }

                onSuccess();
                dispatch(setFirstDepositLoadingTime(0));
            })
            .catch(() => {
                dispatch(fail());
                dispatch(finishSession());
                dispatch(setLoadingPercent(getLoadingPercent(true, 'walletInformation')));
            });
    };
};

export const submitDepositData = (data) => {
    const loading = () => ({
        type: SUBMIT_DEPOSIT
    });
    const success = (payload, method, amount) => ({
        type: SUBMIT_DEPOSIT_SUCCESS,
        payload,
        method,
        amount
    });
    const fail = (payload) => ({
        type: SUBMIT_DEPOSIT_FAIL,
        payload
    });

    const reqDepositFunc = (data, additionalData) => {
        data.paymentInformation = {
            ...data.paymentInformation,
            referer: window.location.href
        };

        return (dispatch, getState) => {
            depositRequestAbortController = new AbortController();
            depositRequest(
                { ...data, additionalData },
                {
                    signal: depositRequestAbortController.signal
                }
            )
                .then((res) => {
                    dispatch(success(res.data, data.paymentInformation.type, data.amount));
                    // Update deposit Remaining when we receive success response immediately
                    if (res.data.data.message) {
                        dispatch(getContentForSlider(router.locale));
                        dispatch(
                            getWalletInformation({
                                isTransactionSuccess: true
                            })
                        );
                    }
                })
                .catch((err) => {
                    // When deposit modal is closed during the request's execution we canceled it and init deposit data
                    if (err.isCanceled) {
                        const initDeposit = () => {
                            dispatch(initSubmitDeposit());
                            dispatch(initTransactionStatus());
                        };
                        return initDeposit();
                    }

                    const tr = getState().global.data.translations;
                    const event =
                        err.status === 404 || err.status === 500
                            ? 'deposit_submission_failed'
                            : 'deposit_transaction_declined';
                    const errorMessage = handleDepositError(err, tr);
                    let dataLayerObj = {
                        event,
                        statusCode: err.status,
                        message: errorMessage
                    };
                    //put extra information to gtm event
                    if (event === 'deposit_transaction_declined' && isPlainObject(err.data?.errors)) {
                        const { message, errors } = err.data.errors;
                        dataLayerObj.messageKey = message;
                        dataLayerObj.errors = errors;
                    }
                    const error = err.data?.errors
                        ? errorMessage
                        : JSON.stringify({
                            status: err.status,
                            detail: err.statusText
                        });
                    dataLayerPush(dataLayerObj);
                    return dispatch(fail(error));
                })
                .finally(() => {
                    // Cleanup abort controller
                    depositRequestAbortController = null;
                });
        };
    };

    return (dispatch, getState) => {
        const selectedMethod = getState().bank.selectedMethodNew;
        const paymentInformation = {
            amount: data.amount,
            method:
                data.paymentInformation.type !== PAYMENT_METHOD_TYPE_REUSED_CARD
                    ? data.paymentInformation.type
                    : selectedMethod.method,
            currency: getState().user.information.currency
        };
        localStorage.setItem('paymentInformation', JSON.stringify(paymentInformation));
        dispatch(loading());
        dataLayerPush({
            event: DEPOSIT_SUBMISSION,
            sub_previous_deposits: getState().bank.information.successfulDepositCount,
            sub_amount: paymentInformation.amount,
            sub_method: paymentInformation.method
        });
        const additionalData = {
            browserJavaEnabled: navigator.javaEnabled(),
            browserLanguage: navigator.language,
            browserColorDepth: screen.colorDepth,
            browserScreenHeight: window.screen.height,
            browserScreenWidth: window.screen.width,
            browserTimezone: new Date().getTimezoneOffset()
        };
        getSeonBase64Session(MODAL_DEPOSIT)
            .then(({ seonBase64Session }) => {
                additionalData.seonSession = seonBase64Session;
                dispatch(reqDepositFunc(data, additionalData));
            })
            .catch(() => {
                dataLayerPush({ event: 'getSeonBase64Session_error', error: 'on_deposit' });
                dispatch(reqDepositFunc(data, additionalData));
            });
    };
};

export const submitDepositCashlibDirect = (data) => {
    const loading = () => ({
        type: SUBMIT_DEPOSIT_CASHLIB_DIRECT
    });
    const success = (payload) => ({
        type: SUBMIT_DEPOSIT_CASHLIB_DIRECT_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: SUBMIT_DEPOSIT_CASHLIB_DIRECT_FAIL,
        payload
    });
    return (dispatch, getState) => {
        dispatch(loading());
        depositRequestAbortController = new AbortController();
        getCashlibDirectBarcodeRequest(data, {
            signal: depositRequestAbortController.signal
        })
            .then((res) => dispatch(success(res.data.data.barcodeUrl)))
            .catch((err) => {
                // When deposit modal is closed during the request's execution we canceled it and init deposit data
                if (err.isCanceled) {
                    const initDeposit = () => {
                        dispatch(initSubmitDeposit());
                        dispatch(initTransactionStatus());
                    };
                    return initDeposit();
                }

                const tr = getState().global.data.translations;
                return dispatch(fail(tr['deposit_error_cashlib_direct']));
            })
            .finally(() => {
                // Cleanup abort controller
                depositRequestAbortController = null;
            });
    };
};

export const submitWithdrawData = (data) => {
    const loading = () => ({
        type: SUBMIT_WITHDRAW
    });
    const success = (payload, method, amount) => ({
        type: SUBMIT_WITHDRAW_SUCCESS,
        payload,
        method,
        amount
    });
    const fail = (payload) => ({
        type: SUBMIT_WITHDRAW_FAIL,
        payload
    });

    const reqWithdrawFunc = (data) => {
        return (dispatch) => {
            withdrawalRequestAbortController = new AbortController();
            withdrawalRequest(
                {
                    ...data
                },
                {
                    signal: withdrawalRequestAbortController.signal
                }
            )
                .then((res) => {
                    const pushLayerData = (successWithdrawals) =>
                        dataLayerPush({
                            event: 'withdraw_success',
                            previous_withdrawals: successWithdrawals,
                            amount: data.amount,
                            method: data.paymentInformation.type,
                            email: data.email
                        });
                    dispatch(success(res.data, data.paymentInformation.type, data.amount));
                    dispatch(getWithdrawalsWallet());
                    dispatch(
                        setWithdrawalResultModal({
                            status: MODAL_STATUS_SUCCESS,
                            data: {
                                amount: data.amount
                            }
                        })
                    );
                    handleCloseModal();
                    transactionsRequest({
                        offset: 0,
                        types: ['withdraw'],
                        status: ['success']
                    })
                        .then((response) => pushLayerData(response.data.data.total))
                        .catch(() => pushLayerData());
                })
                .catch((err) => {
                    // When withdrawal modal is closed during the request's execution we canceled it and init wdr data
                    if (err.isCanceled) {
                        return dispatch(initSubmitWithdraw());
                    }

                    scrollElementToTop('.financial-page--data');
                    if (err.data.errors) {
                        return dispatch(fail(JSON.stringify(err.data.errors)));
                    }
                    return dispatch(
                        fail(
                            JSON.stringify({
                                status: err.status,
                                detail: err.statusText
                            })
                        )
                    );
                })
                .finally(() => {
                    // Cleanup abort controller
                    withdrawalRequestAbortController = null;
                });
        };
    };

    return (dispatch, getState) => {
        const { allClaimedBonuses } = getState().bonus.data;
        const cancelBonuses = () => {
            return new Promise((resolve, reject) => {
                allClaimedBonuses.length === 0 && resolve();
                allClaimedBonuses.forEach((bonus, index) => {
                    const i = index + 1;
                    bonusCancelRequest(bonus.id)
                        .then(() => {
                            dispatch(getBonusData());
                            allClaimedBonuses.length === i && resolve();
                        })
                        .catch((err) => reject(err));
                });
            });
        };
        dispatch(loading());
        cancelBonuses()
            .then(() => {
                getSeonBase64Session(MODAL_WITHDRAW)
                    .then(({ seonBase64Session }) => {
                        data.seonSession = seonBase64Session;
                        dispatch(reqWithdrawFunc(data));
                    })
                    .catch(() => {
                        dataLayerPush({ event: 'getSeonBase64Session_error', error: 'on_withdraw' });
                        dispatch(reqWithdrawFunc(data));
                    });
            })
            .catch((error) => {
                dispatch(fail(JSON.stringify(error.data)));
                scrollElementToTop('.financial-page--data');
            });
    };
};

export const getTransactionStatus = (id) => {
    const loading = (payload) => ({
        type: GET_TRANSACTION_STATUS,
        payload
    });
    const success = (payload) => ({
        type: GET_TRANSACTION_STATUS_SUCCESS,
        payload
    });
    return (dispatch, getState) => {
        const { pendingStatusId } = getState().bank;
        const isCheckingStatus = pendingStatusId === id;

        !isCheckingStatus && dispatch(loading());
        transactionStatusRequest(id)
            .then((res) => {
                if (isCheckingStatus) {
                    dispatch(handlePendingTransaction(res.data.data.status));
                } else {
                    if (res.data.data.status === TRANSACTION_STATUS_PENDING) {
                        dispatch(setPendingStatusId(id));
                    }

                    // We need this call to update deposit Remaining Limit within Iframe
                    if (res.data.data.status === TRANSACTION_STATUS_SUCCESS && !router.asPath.includes(TRANSACTION_ID)) {
                        dispatch(getContentForSlider(router.locale));
                        dispatch(
                            getWalletInformation({
                                isTransactionSuccess: true
                            })
                        );
                    }

                    dispatch(success(res.data));
                }
            })
            .catch((err) => {
                if (isCheckingStatus) {
                    dispatch(setPendingStatusIntervalTime(0));
                    dispatch(setPendingStatusId(null));
                    dispatch(setPendingStatusNotification(NOTIFICATIONS_STATUS_ERROR));
                }
                dispatch(
                    success({
                        data: {
                            status: err.status === 404 ? TRANSACTION_STATUS_NOT_FOUND : TRANSACTION_STATUS_FAILURE,
                            errors: err.data?.errors
                        }
                    })
                );
            });
    };
};

const handlePaymentMethod = (payload, method, amount) => {
    const transactionData = {
        method,
        amount
    };
    if (payload.data.message) {
        return {
            transactionStatus: {
                status: 'success',
                error: ''
            },
            depositResponse: {
                url: '',
                type: ''
            },
            transactionData
        };
    } else {
        return {
            depositResponse: payload.data,
            transactionData
        };
    }
};

const handlePendingTransaction = (status) => {
    const intervals = {
        [PENDING_TIME_INTERVAL_0]: PENDING_TIME_INTERVAL_1,
        [PENDING_TIME_INTERVAL_1]: PENDING_TIME_INTERVAL_2,
        [PENDING_TIME_INTERVAL_2]: PENDING_TIME_INTERVAL_3,
        [PENDING_TIME_INTERVAL_3]: PENDING_TIME_INTERVAL_4,
        [PENDING_TIME_INTERVAL_4]: PENDING_TIME_INTERVAL_0
    };
    return (dispatch, getState) => {
        const { pendingStatusIntervalTime, information } = getState().bank;
        const { amount, method } = JSON.parse(localStorage.getItem('paymentInformation')) || {};
        const pendingEventData = {
            previous_deposits: information.successfulDepositCount,
            amount: amount,
            method: method,
            pending: true
        };
        if (status === TRANSACTION_STATUS_SUCCESS) {
            dispatch(setPendingStatusNotification(NOTIFICATIONS_STATUS_SUCCESS));
            dispatch(setPendingStatusIntervalTime(0));
            dispatch(setPendingStatusId(null));
            dispatch(getContentForSlider(router.locale));
            dispatch(
                getWalletInformation({
                    isTransactionSuccess: true
                })
            );
            dataLayerPush({
                ...pendingEventData,
                event: `deposit_${TRANSACTION_STATUS_SUCCESS}`
            });
        }
        if (status === TRANSACTION_STATUS_FAILURE) {
            dispatch(setPendingStatusNotification(NOTIFICATIONS_STATUS_ERROR));
            dispatch(setPendingStatusIntervalTime(0));
            dispatch(setPendingStatusId(null));
            dataLayerPush({
                ...pendingEventData,
                event: `deposit_${TRANSACTION_STATUS_FAILURE}`
            });
        }
        if (status === TRANSACTION_STATUS_PENDING) {
            const time = intervals[pendingStatusIntervalTime];
            dispatch(setPendingStatusIntervalTime(time));
            if (time === 0) {
                dispatch(setPendingStatusId(null));
                dataLayerPush({
                    ...pendingEventData,
                    event: 'deposit_pending_final'
                });
                localStorage.removeItem('paymentInformation');
            }
        }
    };
};

export const getTransactions = (offset = 0) => {
    const loading = (payload) => ({
        type: GET_TRANSACTIONS,
        payload
    });
    const success = (payload) => ({
        type: GET_TRANSACTIONS_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_TRANSACTIONS_FAILED
    });
    return (dispatch, getState) => {
        const NUMBERS_OF_DAY_BEHIND = 60;
        const currentDate = dayjs();
        const dateFrom = dayjs().subtract(NUMBERS_OF_DAY_BEHIND, 'day');

        const limit = transactionsPerPage[getState().global.currentDevice];
        const formatDate = (date) => {
            return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
        };
        const isInit = offset === 0;

        dispatch(loading(isInit ? TRANSACTIONS_LOADING_TYPE_GLOBAL : TRANSACTIONS_LOADING_TYPE_BUTTON));
        transactionsRequest({
            limit,
            offset,
            status: transactionStatuses,
            dateRangesFrom: formatDate(dateFrom),
            dateRangesTo: formatDate(currentDate)
        })
            .then((res) => {
                const { transaction, total } = res.data.data;
                const { list } = getState().bank.transactions;

                dispatch(
                    success({
                        list: isInit ? [...transaction] : [...list, ...transaction],
                        totalItems: total,
                        offset
                    })
                );
            })
            .catch(() => dispatch(fail()));
    };
};

export const setWithdrawalsWallet = (payload) => ({
    type: GET_WITHDRAWALS_WALLET_SUCCESS,
    payload
});

export const getWithdrawalsWallet = (offset = 0) => {
    const loading = (payload) => ({
        type: GET_WITHDRAWALS_WALLET,
        payload
    });
    const fail = () => ({
        type: GET_WITHDRAWALS_WALLET_FAILED
    });
    return (dispatch, getState) => {
        const isInit = offset === 0;
        dispatch(loading(isInit ? TRANSACTIONS_LOADING_TYPE_GLOBAL : TRANSACTIONS_LOADING_TYPE_BUTTON));
        transactionsRequest({
            offset,
            limit: 3,
            types: [TRANSACTION_TYPE_WITHDRAW],
            status: [
                PAYOUT_STATUS_PENDING,
                PAYOUT_STATUS_VALIDATED,
                PAYOUT_STATUS_PROCESSING,
                PAYOUT_STATUS_REQUESTED,
                // We add error status to the pending withdrawals
                PAYOUT_STATUS_ERROR
            ]
        })
            .then((res) => {
                const { transaction, total } = res.data.data;
                const { list } = getState().bank.withdrawalsWallet;
                const pendingTransaction = handlePendingWithdrawals(transaction);

                dispatch(
                    setWithdrawalsWallet({
                        list: isInit ? [...pendingTransaction] : [...list, ...pendingTransaction],
                        totalItems: total,
                        offset
                    })
                );
            })
            .catch(() => dispatch(fail()));
    };
};

export const initSubmitDeposit = () => ({
    type: SUBMIT_DEPOSIT_INIT
});

export const selectBonus = (payload) => ({
    type: SELECT_BONUS,
    payload
});

export const initTransactionStatus = () => ({
    type: TRANSACTION_STATUS_INIT
});

export const setDepositActiveStep = (payload) => ({
    type: SET_DEPOSIT_ACTIVE_STEP,
    payload
});

export const getSocketBalance = (payload) => ({
    type: GET_SOCKET_BALANCE,
    payload
});

export const getBetRace = () => {
    const success = (payload) => ({
        type: GET_BET_RACE,
        payload
    });
    return (dispatch) => {
        getBetRaceRequest()
            .then((res) => dispatch(success(res.data)))
            .catch((err) => console.error(JSON.stringify(err.data)));
    };
};

export const getPayoutMethods = () => {
    const loading = () => ({
        type: GET_PAYOUT_METHODS
    });
    const success = (payload) => ({
        type: GET_PAYOUT_METHODS_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_PAYOUT_METHODS_FAILED
    });
    return (dispatch) => {
        dispatch(loading());
        getPaymentConfig('payout')
            .then((res) => {
                if (res.data.data.length > 0) {
                    dispatch(success(res.data));
                } else {
                    dispatch(fail());
                }
            })
            .catch(() => dispatch(fail()));
    };
};

export const initPayoutMethods = () => ({
    type: INIT_PAYOUT_METHODS
});

export const initSubmitWithdraw = () => ({
    type: SUBMIT_WITHDRAW_INIT
});

export const cleanUpCancelWithdrawal = () => ({
    type: CLEANUP_CANCEL_WITHDRAWAL
});

export const cancelWithdrawal = (item, onFinally) => {
    const loading = (payload) => ({
        type: CANCEL_WITHDRAWAL,
        payload
    });
    const success = (payload) => ({
        type: CANCEL_WITHDRAWAL_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: CANCEL_WITHDRAWAL_FAIL,
        payload
    });
    return (dispatch) => {
        dispatch(cleanUpCancelWithdrawal());
        dispatch(loading(item.movementId));
        cancelWithdrawalRequest(item.movementId)
            .then(() => {
                dispatch(success(item));
                dataLayerPush({
                    event: 'WTD_Cancel',
                    deposit_flow: router.query.modal === MODAL_DEPOSIT
                });
            })
            .catch((err) => dispatch(fail(err)))
            .finally(() => onFinally());
    };
};

export const refreshWalletTransactions = () => {
    return (dispatch) => {
        if (router.asPath.split('?')[0] === routes.wallet) {
            dispatch(getTransactions());
        }
    };
};

export const setFirstDepositLoadingTime = (payload) => ({
    type: GET_FIRST_DEPOSIT_REMAINING_TIME_SUCCESS,
    payload
});

export const getFirstDepositRemainingTime = (onFinally) => {
    const loading = () => ({
        type: GET_FIRST_DEPOSIT_REMAINING_TIME
    });
    const fail = () => ({
        type: GET_FIRST_DEPOSIT_REMAINING_TIME_FAILED
    });
    return (dispatch) => {
        dispatch(loading());
        firstDepositRemainingTimeRequest()
            .then((res) => {
                const ftdBonusTimeRemaining = res.data.ftdBonusTimeRemaining || 0;
                dispatch(setFirstDepositLoadingTime(ftdBonusTimeRemaining));
            })
            .catch(() => dispatch(fail()))
            .finally(() => onFinally());
    };
};

export const setFirstDepositOfferModalOpened = (payload) => ({
    type: SET_FIRST_DEPOSIT_OFFER_MODAL_OPENED,
    payload
});

export const setDepositCloseHandler = (payload) => ({
    type: SET_DEPOSIT_CLOSE_HANDLER,
    payload
});

export const setConvertCryptoModalOpen = (payload) => ({
    type: SET_CONVERT_CRYPTO_MODAL_OPEN,
    payload
});

export const setSuccessfulDepositCount = (payload) => ({
    type: SET_SUCCESSFULL_DEPOSIT_COUNT,
    payload
});

export const setWithdrawalResultModal = (payload) => ({
    type: SET_WITHDRAWAL_RESULT_MODAL,
    payload
});

export const selectWithdrawalMethod = (payload) => ({
    type: SELECT_WITHDRAWAL_METHOD,
    payload
});

export const setPendingStatusIntervalTime = (payload) => ({
    type: SET_PENDING_STATUS_INTERVAL_TIME,
    payload
});

export const setPendingStatusNotification = (payload) => ({
    type: SET_PENDING_STATUS_NOTIFICATION,
    payload
});

export const setPendingStatusId = (payload) => ({
    type: SET_PENDING_STATUS_ID,
    payload
});

export const initBankInformation = () => ({
    type: INIT_BANK_INFORMATION
});

export const selectMethodNew = (payload) => ({
    type: SELECT_METHOD_NEW,
    payload
});

export const getPaymentMethodsSuccess = (payload) => ({
    type: GET_PAYMENT_METHODS_NEW_SUCCESS,
    payload
});

const handlePaymentMethods = (paymentMethods = [], cards = []) => {
    return (dispatch, getState) => {
        const bankCardMethodsAvailable = paymentMethods.filter((item) => bankCardMethods.includes(item.method));
        const isCAUser = getState().user.information.country === COUNTRY_CODE_CA;
        const getBankCards = (lastDepositMethod) => {
            if (bankCardMethodsAvailable.length) {
                const newBankCardMethod = {
                    ...bankCardMethodsAvailable[0],
                    id: 0,
                    method: PAYMENT_METHOD_SYNTHETIC_NEW_BANK_CARD
                };

                const getFilteredCards = () => {
                    const filtered = [...cards].filter((card) =>
                        bankCardMethodsAvailable.some((item) => item.method === card.type)
                    );
                    const elementIndex = filtered.findIndex((item) => item.type === lastDepositMethod);

                    if (elementIndex > -1) {
                        const cardsArray = [...filtered.slice(0, elementIndex), ...filtered.slice(elementIndex + 1)];
                        return [filtered[elementIndex], ...cardsArray];
                    }

                    return [...filtered];
                };

                const bankCards = getFilteredCards()
                    .sort((a, b) => (a.default === b.default ? 0 : a.default ? -1 : 1))
                    .map((card) => {
                        const paymentMethod = bankCardMethodsAvailable.find((item) => item.method === card.type);
                        return {
                            ...paymentMethod,
                            card: {
                                ...card,
                                cardLabel: formatCardSuffix(card.cardNumberSuffix)
                            }
                        };
                    });

                return [newBankCardMethod, ...bankCards];
            }

            return [];
        };

        //Pass lastDepositMethod for proper sorting
        const getMethods = (lastDepositMethod) => {
            // Check for ApplePay availability
            // eslint-disable-next-line no-undef
            const isApplePayAvailable = window.ApplePaySession && ApplePaySession.canMakePayments();
            return {
                bankCards: getBankCards(lastDepositMethod),
                other: [...paymentMethods]
                    .filter((item) =>
                        item.method === PAYMENT_METHOD_APPLE_PAY
                            ? isApplePayAvailable
                            : !bankCardMethods.includes(item.method)
                    )
                    .sort((a, b) =>
                        isCAUser && a.method === PAYOUT_METHOD_INTERAC
                            ? -1
                            : isCAUser && b.method === PAYOUT_METHOD_INTERAC
                                ? 1
                                : 0
                    )
                    .sort((a, b) => (a.method === lastDepositMethod ? -1 : b.method === lastDepositMethod ? 1 : 0))
            };
        };

        const finishSetup = (lastDepositMethod) => {
            const methods = getMethods(lastDepositMethod);
            const lastDepositPaymentMethod = [...methods.bankCards, ...methods.other].find(
                (item) => item.method === lastDepositMethod
            );
            const isCaInterac = isCAUser && methods.other[0]?.method === PAYOUT_METHOD_INTERAC;

            //Pre-select payment method in following priority: default bank card or first bank card in the list => new bank card => first non-card method
            const selectedMethod = isCaInterac
                ? methods.other[0]
                : methods.bankCards.length
                    ? methods.bankCards.length > 1
                        ? methods.bankCards[1]
                        : methods.bankCards[0]
                    : methods.other[0];

            dispatch(getPaymentMethodsSuccess(methods));
            dispatch(setActiveCardMethods(bankCardMethodsAvailable));
            dispatch(selectMethodNew(lastDepositPaymentMethod || selectedMethod));
        };

        // pre-select method and amount from the last deposit
        transactionsRequest({
            offset: 0,
            status: [TRANSACTION_STATUS_SUCCESS],
            order: DESC,
            limit: 1,
            types: ['deposit']
        })
            .then((res) => {
                const lastDeposit = res.data.data.transaction[0];
                if (lastDeposit) {
                    const { amount, paymentMethod } = lastDeposit;
                    const nearestAmount = minBy(
                        amountRange.map((item) => Number(item)),
                        (item) => Math.abs(item - Number(amount))
                    );
                    dispatch(setDepositAmount(`${nearestAmount}`));
                    finishSetup(paymentMethod);
                } else {
                    finishSetup();
                }
            })
            .catch(() => finishSetup());
    };
};

const setPaymentMethodsNew = (paymentMethods) => {
    return (dispatch) => {
        userCardsRequest()
            .then((cardsData) => {
                dispatch(handlePaymentMethods(paymentMethods, cardsData.data.data));
            })
            .catch(() => {
                dispatch(handlePaymentMethods(paymentMethods, []));
            });
    };
};

export const getPaymentMethodsNew = () => {
    const loading = () => ({
        type: GET_PAYMENT_METHODS_NEW
    });
    const fail = () => ({
        type: GET_PAYMENT_METHODS_NEW_FAILED
    });
    return (dispatch) => {
        dispatch(loading());
        getPaymentConfig('payin')
            .then((res) => {
                const paymentMethods = getOrderedPaymentMethods(res.data.data, paymentMethodsOrdering);
                if (paymentMethods.length > 0) {
                    dispatch(setPaymentMethodsNew(paymentMethods));
                } else {
                    dispatch(fail());
                }
            })
            .catch(() => dispatch(fail()));
    };
};

export const getPendingWithdrawalsDepositNew = (pushDepositStartEvent = null, offset = 0, limit = 5) => {
    const loading = (payload) => ({
        type: GET_PENDING_WITHDRAWALS_DEPOSIT_NEW,
        payload
    });
    const fail = () => ({
        type: GET_PENDING_WITHDRAWALS_DEPOSIT_NEW_FAILED
    });

    return (dispatch, getState) => {
        const isInit = offset === 0;
        const pushEvent = (pendingWdr) => pushDepositStartEvent && pushDepositStartEvent(pendingWdr);
        dispatch(loading(isInit ? TRANSACTIONS_LOADING_TYPE_GLOBAL : TRANSACTIONS_LOADING_TYPE_BUTTON));

        transactionsRequest({
            offset,
            limit,
            types: [TRANSACTION_TYPE_WITHDRAW],
            status: [PAYOUT_STATUS_PENDING, PAYOUT_STATUS_REQUESTED]
        })
            .then((res) => {
                const { transaction, total } = res.data.data;
                const { list } = getState().bank.withdrawalsDeposit;
                const pendingWithdrawals = handlePendingWithdrawals(transaction);

                dispatch(
                    setPendingWithdrawalsDepositNew({
                        list: isInit ? [...pendingWithdrawals] : [...list, ...pendingWithdrawals],
                        totalItems: total,
                        offset
                    })
                );
                pushEvent(total);
            })
            .catch(() => {
                dispatch(fail());
                pushEvent(undefined);
            });
    };
};

export const setPendingWithdrawalsDepositNew = (payload) => ({
    type: GET_PENDING_WITHDRAWALS_DEPOSIT_NEW_SUCCESS,
    payload
});

export const startNewDepositFlow = (depositCount) => {
    return (dispatch) => {
        const pushDepositStartEvent = (pendingWdr) =>
            dataLayerPush({
                event: DEPOSIT_START,
                previous_deposits: depositCount,
                flow: 'new',
                Pending_WTDs: pendingWdr
            });
        dispatch(setSuccessfulDepositCount(depositCount));
        dispatch(getBonusData(false, true));
        dispatch(getPendingWithdrawalsDepositNew(pushDepositStartEvent));
        dispatch(getPaymentMethodsNew());
    };
};

export const setFirstDepositOfferBubbleVisible = (payload) => ({
    type: SET_FIRST_DEPOSIT_OFFER_BUBBLE_VISIBLE,
    payload
});

export const setDepositCurrentView = (payload) => ({
    type: SET_DEPOSIT_CURRENT_VIEW,
    payload
});

export const setActiveCardMethods = (payload) => ({
    type: SET_ACTIVE_CARD_METHODS,
    payload
});

export const setDepositAmount = (payload) => ({
    type: SET_DEPOSIT_AMOUNT,
    payload
});
