import Link from 'next/link';

const GameListItemDefault = ({ game, dataId, linkHandler, clickHandler }) => {
    return (
        <Link {...linkHandler()}>
            <a className="game-list-item" data-id={dataId}>
                <div className="game-list-item-image" onClick={(e) => clickHandler(e, game)} data-testid={`game_${game.id}`}>
                    <img src={`${game.metadata.thumbnailBackground}`} alt={game.name} />
                </div>
            </a>
        </Link>
    );
};

export default GameListItemDefault;
