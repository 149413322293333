import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import countries from '@tlf-e/brand-utils/dist/static-data/countries';
import BannerVideo from './BannerVideo';
import { formatBannerCurrency } from '../../../utils/formatCurrency';

const Banner = ({
    title,
    sub_title_1 = '',
    sub_title_2,
    button_text,
    button_link,
    video_webm,
    video_mp4,
    video_fallback_img,
    box_shadow,
    offer_bg,
    blur_effect_bg,
    amount
}) => {
    const isAuth = useSelector((state) => state.user.isAuth);
    const { videoAutoplayEnabled, clientIp } = useSelector((state) => state.global);
    // No subscription on isAuth to prevent showing broken data.
    // We have proper sequence of requests when slider is calling after auth request
    const currencyTitle = useMemo(() => {
        if (!isAuth) {
            const currency = countries.find((country) => country.code === clientIp.loc)?.currency;
            const amountData = amount ? formatBannerCurrency(amount, currency?.code) : '';
            return sub_title_1?.replace('##AMOUNT##', amountData);
        }
        return sub_title_1;
    }, [sub_title_1, amount, clientIp.loc]);

    return (
        <div className="banner" style={{ boxShadow: box_shadow, background: offer_bg }}>
            <style dangerouslySetInnerHTML={{ __html: `.offer:after { background: ${blur_effect_bg} }` }} />
            <div className="offer">
                <div className="offer-details">
                    <div>
                        <h3 dangerouslySetInnerHTML={{ __html: title }} />
                        <h1 dangerouslySetInnerHTML={{ __html: currencyTitle }} />
                        <h2 dangerouslySetInnerHTML={{ __html: sub_title_2 }} />
                    </div>
                    <div>
                        <Link href={button_link}>
                            <a className="btn btn-modern btn-submit">{button_text}</a>
                        </Link>
                    </div>
                </div>
            </div>
            {videoAutoplayEnabled ? (
                <BannerVideo video_fallback_img={video_fallback_img} video_webm={video_webm} video_mp4={video_mp4} />
            ) : (
                <img className="banner--bg" src={video_fallback_img} alt="Browser does not support video tag" />
            )}
        </div>
    );
};

export default Banner;
