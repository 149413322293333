import { useSelector } from 'react-redux';
import LobbyWelcome from './fragments/lobby-welcome';
import LobbyGames from './fragments/lobby-games';
import LobbyLives from './fragments/lobby-lives';
import LobbyPromotions from './fragments/lobby-promotions';
import LobbyFavorites from './fragments/lobby-favorites';
import LobbyTopGames from './fragments/lobby-top-games';

const LobbyDesktop = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const isAuth = useSelector((state) => state.user.isAuth);
    const hasLiveGames = useSelector((state) => state.games.hasLiveGames);
    const defaultGamesLimit = 12;

    return (
        <main>
            <div className="page-container lobby-page">
                <LobbyWelcome tr={tr} />
                <LobbyGames tr={tr} defaultGamesLimit={defaultGamesLimit} />
                <LobbyTopGames tr={tr} defaultGamesLimit={defaultGamesLimit} />
                <LobbyPromotions tr={tr} />
                {isAuth && <LobbyFavorites tr={tr} defaultGamesLimit={defaultGamesLimit} />}
                {hasLiveGames && <LobbyLives tr={tr} />}
            </div>
        </main>
    );
};

export default LobbyDesktop;
