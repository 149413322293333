import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import Play from '../../../../../svg-icons/game-thumbs/Play';
import { GAMES_VOLATILITY_DEFAULT } from '../../../../../../variables';
import cx from 'classnames';
import VltProgressBar from '../../../../../common/vlt-progress-bar';
import GameProviderLogo from '../../../../../common/GameProviderLogo';
import routes from '../../../../../../data/routes.json';

const GameListItemDefault = ({ game, url, as, saveCurrentUrl, dataId, provider, isPromotionModal }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const paths = useSelector((state) => state.global.paths);
    const [isHovered, setIsHovered] = useState(false);
    const { rtp, volatility } = game.metadata;

    return (
        <div className="game-list-item" data-id={dataId}>
            <div className="game-list-item--background">
                <img src={`${game.metadata.thumbnailBackground}`} alt={game.name} />
            </div>
            <Link href={url} as={as}>
                <a className="game-list-item--link" onClick={saveCurrentUrl} data-testid={`game_${game.id}`}>
                    <Play />
                </a>
            </Link>
            <Fragment>
                <div className="game-list-item--text" />
                <div className="game-list-item--text-title">
                    <div>{game.name}</div>
                </div>
                <div className="game-list-item--text-rtp">
                    <div className="game-rtp">
                        <div>{tr['rtp']}</div>
                        <div className={cx('value', { 'promo-game': isPromotionModal })}>{rtp}%</div>
                    </div>
                </div>
                <div className="game-list-item--text-info">
                    <div
                        data-testid={`game_volatility_${game.id}`}
                        className={cx('game-vlt', { default: volatility === GAMES_VOLATILITY_DEFAULT })}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <VltProgressBar vlt={volatility} isHovered={isHovered} />
                    </div>
                    {provider && (
                        <Link
                            href={`${routes.games}?providers=${provider.slug}`}
                            as={`${paths[routes.games]}?providers=${provider.slug}`}
                        >
                            <a>
                                <GameProviderLogo slug={provider.slug} />
                            </a>
                        </Link>
                    )}
                </div>
            </Fragment>
        </div>
    );
};

export default GameListItemDefault;
