import router from 'next/router';
import dataLayerPush from '@tlf-e/brand-utils/dist/helpers/dataLayerPush';
import { wheelOfFortuneWorkers } from '../../services/http';
import { WOF_STANDINGS_FETCH, WOF_STANDINGS_REFRESH } from '../../variables';
import routes from '../../data/routes.json';

const { getWofRequest, getTimeToSpinRequest, getWofStatusRequest, getWofAllStandingsRequest } = wheelOfFortuneWorkers;

const GET_WOF = 'GET_WOF';
const GET_WOF_SUCCESS = 'GET_WOF_SUCCESS';
const GET_WOF_FAILED = 'GET_WOF_FAILED';
const GET_WOF_STANDINGS = 'GET_WOF_STANDINGS';
const GET_WOF_STANDINGS_SUCCESS = 'GET_WOF_STANDINGS_SUCCESS';
const GET_WOF_STANDINGS_FAILED = 'GET_WOF_STANDINGS_FAILED';
const GET_TIME_TO_SPIN = 'GET_TIME_TO_SPIN';
const GET_TIME_TO_SPIN_SUCCESS = 'GET_TIME_TO_SPIN_SUCCESS';
const GET_TIME_TO_SPIN_FAILED = 'GET_TIME_TO_SPIN_FAILED';
const SET_IS_WOF_PAGE_CONTENT_LOADED = 'SET_IS_WOF_PAGE_CONTENT_LOADED';
const SET_WHEEL_MODE = 'SET_WHEEL_MODE';
const SET_WHEEL_ORIGIN = 'SET_WHEEL_ORIGIN';
const SET_IS_WOF_ENABLED = 'SET_IS_WOF_ENABLED';
const INIT_WHEEL_STATE = 'INIT_WHEEL_STATE';

export const initialState = {
    isWofEnabled: false,
    isWofStatusLoaded: false,
    wof: null,
    isLoading: false,
    isWofPageContentLoaded: false,
    wofStandings: {
        all: [],
        player: null
    },
    wofStandingsLoadingType: '',
    timeToSpin: 0,
    timeToSpinLoading: false,
    timeToSpinLoaded: false,
    wheelMode: '',
    wheelOrigin: ''
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case GET_WOF:
        return {
            ...state,
            isLoading: true
        };
    case GET_WOF_SUCCESS:
        const { allStandings, ...wof } = action.payload;
        return {
            ...state,
            wof,
            isLoading: false,
            isWofPageContentLoaded: action.isWofPage,
            wofStandings: handleAllPlayersStandings(allStandings.playerStandings, allStandings.standings)
        };
    case GET_WOF_FAILED:
        return {
            ...state,
            wof: null,
            isLoading: false,
            isWofPageContentLoaded: action.isWofPage,
            timeToSpinLoaded: true,
            wofStandings: {
                all: [],
                player: null
            }
        };
    case GET_WOF_STANDINGS:
        return {
            ...state,
            wofStandingsLoadingType: action.payload
        };
    case GET_WOF_STANDINGS_SUCCESS:
        return {
            ...state,
            wofStandings: action.payload,
            wofStandingsLoadingType: ''
        };
    case GET_WOF_STANDINGS_FAILED:
        return {
            ...state,
            wofStandingsLoadingType: ''
        };
    case GET_TIME_TO_SPIN:
        return {
            ...state,
            timeToSpinLoading: true
        };
    case GET_TIME_TO_SPIN_SUCCESS:
        return {
            ...state,
            timeToSpin: action.payload,
            timeToSpinLoading: false,
            timeToSpinLoaded: true
        };
    case GET_TIME_TO_SPIN_FAILED:
        return {
            ...state,
            timeToSpinLoading: false,
            timeToSpinLoaded: true
        };
    case SET_IS_WOF_PAGE_CONTENT_LOADED:
        return {
            ...state,
            isWofPageContentLoaded: action.payload
        };
    case SET_WHEEL_MODE:
        return {
            ...state,
            wheelMode: action.payload
        };
    case SET_WHEEL_ORIGIN:
        return {
            ...state,
            wheelOrigin: action.payload
        };
    case SET_IS_WOF_ENABLED:
        return {
            ...state,
            isWofEnabled: action.payload,
            isWofStatusLoaded: action.isLoaded
        };
    case INIT_WHEEL_STATE:
        return initialState;
    default:
        return state;
    }
};

export const getWof = (isWofPage) => {
    const loading = () => ({
        type: GET_WOF
    });
    const success = (payload) => ({
        type: GET_WOF_SUCCESS,
        payload,
        isWofPage
    });
    const fail = () => ({
        type: GET_WOF_FAILED,
        isWofPage
    });
    return (dispatch, getState) => {
        dispatch(loading());
        const handleRestrictedStatus = () => {
            isWofPage && router.push(routes.home);
            dispatch(fail());
        };
        const getWofData = (isEnabled) => {
            if (isEnabled) {
                getWofRequest()
                    .then((res) => {
                        const [wof] = res.data.data;
                        const wofId = wof?.id;
                        if (wofId) {
                            dispatch(success(wof));
                            if (!isWofPage) {
                                dispatch(getTimeToSpin(wofId));
                            }
                        } else {
                            dispatch(fail());
                        }
                    })
                    .catch(() => dispatch(fail()));
            } else handleRestrictedStatus();
        };
        const { isWofEnabled, isWofStatusLoaded } = getState().wheel_of_fortune;

        // Prevent calling request for status if it's already loaded
        if (isWofStatusLoaded) {
            getWofData(isWofEnabled);
        } else {
            getWofStatusRequest()
                .then((status) => {
                    dispatch(setIsWofEnabled(status.data.status, true));
                    getWofData(status.data.status);
                })
                .catch(() => {
                    handleRestrictedStatus();
                    dispatch(setIsWofEnabled(false, false));
                });
        }
    };
};

const handleAllPlayersStandings = (playerStandingsData, allPlayersStandingsData) => {
    return {
        player: playerStandingsData || null,
        all:
            (playerStandingsData?.position > 6
                ? [...allPlayersStandingsData.slice(0, 5), playerStandingsData]
                : [...allPlayersStandingsData]) || []
    };
};
export const getWofStandings = (id, loadingType = WOF_STANDINGS_FETCH, limit = 6) => {
    const loading = (payload) => ({
        type: GET_WOF_STANDINGS,
        payload
    });
    const success = (payload) => ({
        type: GET_WOF_STANDINGS_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_WOF_STANDINGS_FAILED
    });
    return (dispatch, getState) => {
        dispatch(loading(loadingType));
        getWofAllStandingsRequest(id, { limit })
            .then((allStandings) => {
                const isRefresh = loadingType === WOF_STANDINGS_REFRESH;
                const allPlayersStandingsData = allStandings.data.data.standings;
                const playerStandingsData = allStandings.data.data.playerStandings;
                if (isRefresh) {
                    const { id: playerId } = getState().user.information;
                    dataLayerPush({
                        event: 'wf_refresh_leaderboard',
                        player_id: playerId,
                        player_current_position: playerStandingsData?.position
                    });
                }
                dispatch(success(handleAllPlayersStandings(playerStandingsData, allPlayersStandingsData)));
            })
            .catch(() => dispatch(fail()));
    };
};

export const setTimeToSpin = (payload) => ({
    type: GET_TIME_TO_SPIN_SUCCESS,
    payload
});

export const getTimeToSpin = (id, onSuccess, onError) => {
    const loading = () => ({
        type: GET_TIME_TO_SPIN
    });
    const fail = () => ({
        type: GET_TIME_TO_SPIN_FAILED
    });
    return (dispatch, getState) => {
        // Prevent calling timeToSpin request on logout from wheel page
        if (!getState().user.isAuth) {
            return dispatch(initWheelState());
        }

        dispatch(loading());
        getTimeToSpinRequest(id)
            .then((res) => {
                const timeToSpin = res.data.data.timeToSpin || 0;
                dispatch(setTimeToSpin(timeToSpin));
                onSuccess && onSuccess(timeToSpin);
            })
            .catch(() => {
                dispatch(fail());
                onError && onError();
            });
    };
};

export const setIsWofContentPageLoaded = (payload) => ({
    type: SET_IS_WOF_PAGE_CONTENT_LOADED,
    payload
});

export const setWheelMode = (payload) => ({
    type: SET_WHEEL_MODE,
    payload
});

export const setWheelOrigin = (payload) => ({
    type: SET_WHEEL_ORIGIN,
    payload
});

export const setIsWofEnabled = (payload, isLoaded) => ({
    type: SET_IS_WOF_ENABLED,
    payload,
    isLoaded
});

export const initWheelState = () => ({
    type: INIT_WHEEL_STATE
});
