import dayjs from 'dayjs';
import orderLive from '@tlf-e/brand-utils/dist/games/gamesCommon/orderLive';
import reduceContentGameIds from '@tlf-e/brand-utils/dist/games/gamesCommon/reduceContentGameIds';
import {
    GAMES_SORT_KEY_DEFAULT,
    GAMES_SORT_KEY_MOST_RECENT,
    GAMES_SORT_KEY_AZ,
    GAMES_SORT_KEY_VOLATILITY_HIGH,
    GAMES_SORT_KEY_VOLATILITY_LOW,
    GAMES_SORT_KEY_PAYOUT_HIGH,
    GAMES_SORT_KEY_PAYOUT_LOW,
    GAMES_SORT_KEY_OLDEST,
    GAMES_SORT_KEY_BET_LIMITS_LOW,
    GAMES_SORT_KEY_BET_LIMITS_HIGH,
    FILTERS_LIVE_FAVORITE,
    FILTERS_LIVE_BACCARAT,
    FILTERS_LIVE_BLACKJACK,
    FILTERS_LIVE_GAME_SHOWS,
    FILTERS_LIVE_POKER,
    FILTERS_LIVE_ROULETTE,
    FILTERS_LIVE_SIC_BO,
    GAMES_SORT_KEY_ZA,
    ASC,
    DESC,
    GAMES_FILTER_GROUP_VOLATILITY,
    GAMES_FILTER_GROUP_BONUS_BET,
    GAMES_FILTER_GROUP_PROVIDERS,
    NAV_GAMES_ALL,
    NAV_GAMES_FAVORITE,
    NAV_GAMES_RECENTLY_PLAYED,
    NAV_GAMES_SLOT_MACHINES,
    NAV_GAMES_TABLE,
    NAV_GAMES_OTHER,
    GAME_CATEGORY_SLOTS,
    GAME_CATEGORY_TABLE,
    GAME_CATEGORY_OTHER
} from '../variables';
import routes from '../data/routes.json';
import AllLiveGames from '../components/svg-icons/live-nav-icons/AllLiveGames';
import Baccarat from '../components/svg-icons/live-nav-icons/Baccarat';
import Blackjack from '../components/svg-icons/live-nav-icons/Blackjack';
import GameShows from '../components/svg-icons/live-nav-icons/GameShows';
import Poker from '../components/svg-icons/live-nav-icons/Poker';
import Roulette from '../components/svg-icons/live-nav-icons/Roulette';
import SicBo from '../components/svg-icons/live-nav-icons/SicBo';
import Slots from '../components/svg-icons/navigation-icons/Slots';
import TableGames from '../components/svg-icons/navigation-icons/TableGames';
import OtherGames from '../components/svg-icons/navigation-icons/OtherGames';
import FavoriteGames from '../components/svg-icons/navigation-icons/FavoriteGames';
import RecentlyPlayedGames from '../components/svg-icons/navigation-icons/RecentlyPlayedGames';
import { gameDefaultThumbnail } from './gameDefaultThumbnail';

const sort = (games, direction, value) => {
    const arrValue = value.split('.');
    let sortedList = games.slice().sort(function (a, b) {
        if (arrValue.length === 1) {
            const valueA = typeof a[arrValue] === 'string' ? a[arrValue].toLowerCase() : a[arrValue];
            const valueB = typeof b[arrValue] === 'string' ? b[arrValue].toLowerCase() : b[arrValue];
            if (valueA < valueB) {
                return direction === ASC ? -1 : 1;
            }
            if (valueA > valueB) {
                return direction === ASC ? 1 : -1;
            }
            return 0;
        } else {
            if (a[arrValue[0]][arrValue[1]] < b[arrValue[0]][arrValue[1]]) {
                return direction === ASC ? -1 : 1;
            }
            if (a[arrValue[0]][arrValue[1]] > b[arrValue[0]][arrValue[1]]) {
                return direction === ASC ? 1 : -1;
            }
            return 0;
        }
    });
    return sortedList;
};

export const volatilityPriority = ['high', 'mediumHigh', 'medium', 'lowMedium', 'low', null];

const sortMethods = {
    [GAMES_SORT_KEY_DEFAULT]: (games) => games,
    [GAMES_SORT_KEY_MOST_RECENT]: (games) =>
        games.sort((a, b) => new Date(b.metadata.activatedAt) - new Date(a.metadata.activatedAt)),
    [GAMES_SORT_KEY_OLDEST]: (games) =>
        games.sort((a, b) => new Date(a.metadata.activatedAt) - new Date(b.metadata.activatedAt)),
    [GAMES_SORT_KEY_AZ]: (games) => sort(games, ASC, 'name'),
    [GAMES_SORT_KEY_ZA]: (games) => sort(games, DESC, 'name'),
    [GAMES_SORT_KEY_VOLATILITY_HIGH]: (games) =>
        games.slice().sort(function (a, b) {
            let firstPrio = volatilityPriority.indexOf(a.metadata.volatility);
            let secPrio = volatilityPriority.indexOf(b.metadata.volatility);
            return firstPrio - secPrio;
        }),
    [GAMES_SORT_KEY_VOLATILITY_LOW]: (games) =>
        games.slice().sort(function (a, b) {
            let firstPrio = volatilityPriority.indexOf(a.metadata.volatility);
            let secPrio = volatilityPriority.indexOf(b.metadata.volatility);
            return secPrio - firstPrio;
        }),
    [GAMES_SORT_KEY_PAYOUT_HIGH]: (games) => sort(games, ASC, 'metadata.rtp'),
    [GAMES_SORT_KEY_PAYOUT_LOW]: (games) => sort(games, DESC, 'metadata.rtp'),
    [GAMES_SORT_KEY_BET_LIMITS_LOW]: (games) => sort(games, ASC, 'betLimitMin'),
    [GAMES_SORT_KEY_BET_LIMITS_HIGH]: (games) => sort(games, DESC, 'betLimitMax')
};

export const sortGamesByKey = (key, games) => {
    return sortMethods[key]([...games]);
};

export const gamesNavigation = {
    [NAV_GAMES_ALL]: {
        icon: <AllLiveGames />,
        path: routes.games
    },
    [NAV_GAMES_RECENTLY_PLAYED]: {
        icon: <RecentlyPlayedGames />,
        path: routes.recently_played
    },
    [NAV_GAMES_FAVORITE]: {
        icon: <FavoriteGames />,
        path: routes.favorite_games
    },
    [NAV_GAMES_SLOT_MACHINES]: {
        icon: <Slots />,
        path: routes.slot_machines,
        name: GAME_CATEGORY_SLOTS
    },
    [NAV_GAMES_TABLE]: {
        icon: <TableGames />,
        path: routes.table_games,
        name: GAME_CATEGORY_TABLE
    },
    [NAV_GAMES_OTHER]: {
        icon: <OtherGames />,
        path: routes.other_games,
        name: GAME_CATEGORY_OTHER
    }
};

export const liveFilters = {
    [FILTERS_LIVE_FAVORITE]: <FavoriteGames />,
    [FILTERS_LIVE_BACCARAT]: <Baccarat />,
    [FILTERS_LIVE_BLACKJACK]: <Blackjack />,
    [FILTERS_LIVE_GAME_SHOWS]: <GameShows />,
    [FILTERS_LIVE_POKER]: <Poker />,
    [FILTERS_LIVE_ROULETTE]: <Roulette />,
    [FILTERS_LIVE_SIC_BO]: <SicBo />
};

export const liveSorting = [
    GAMES_SORT_KEY_DEFAULT,
    GAMES_SORT_KEY_AZ,
    GAMES_SORT_KEY_ZA,
    GAMES_SORT_KEY_OLDEST,
    GAMES_SORT_KEY_MOST_RECENT,
    GAMES_SORT_KEY_BET_LIMITS_LOW,
    GAMES_SORT_KEY_BET_LIMITS_HIGH
];

export const gamesFilters = [GAMES_FILTER_GROUP_VOLATILITY, GAMES_FILTER_GROUP_BONUS_BET, GAMES_FILTER_GROUP_PROVIDERS];

export const gamesSorting = [
    GAMES_SORT_KEY_DEFAULT,
    GAMES_SORT_KEY_AZ,
    GAMES_SORT_KEY_ZA,
    GAMES_SORT_KEY_OLDEST,
    GAMES_SORT_KEY_MOST_RECENT,
    GAMES_SORT_KEY_PAYOUT_LOW,
    GAMES_SORT_KEY_PAYOUT_HIGH
];

export const hasSubcategoryCategories = [NAV_GAMES_SLOT_MACHINES, NAV_GAMES_TABLE, NAV_GAMES_OTHER];

export const getLiveTables = (allLiveGames, liveGamesLists, filterKey) => {
    const currentList = liveGamesLists?.find((item) => item.slug === filterKey);
    const liveGamesByDate = allLiveGames.sort((a, b) => dayjs(b.metadata.updatedAt) - dayjs(a.metadata.updatedAt)).reverse();

    const filteredGames = Object.keys(liveFilters).includes(filterKey)
        ? liveGamesByDate.filter((game) => currentList?.games.some((id) => game.id === id))
        : liveGamesByDate;
    return orderLive(currentList?.order, filteredGames);
};

export const getCategoryGames = (gamesByCategory, editorChoiceIds = []) => {
    // Get games from content block
    const editorChoiceGamesList = reduceContentGameIds(editorChoiceIds, gamesByCategory);

    // Get rest of the games
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const restGames = gamesByCategory
        .filter((game) => !editorChoiceGamesList.some((item) => item.id === game.id))
        .sort((a, b) => dayjs(b.metadata?.activatedAt, dateFormat) - dayjs(a.metadata?.activatedAt, dateFormat));

    return [...editorChoiceGamesList, ...restGames];
};

export const generateGameThumbnailUrl = (game, isLargeBg, firstGameImageLink) => {
    let gameThumbnail = gameDefaultThumbnail();
    if (isLargeBg) {
        if (firstGameImageLink) {
            gameThumbnail = firstGameImageLink;
        } else if (game?.thumbnail) {
            gameThumbnail = game.thumbnail.replace('size=M', 'size=L');
        }
    } else if (game?.thumbnail) {
        gameThumbnail = game.thumbnail;
    }
    return gameThumbnail;
};
